const data = [
	['braillearmy', 'brailleskateboarding', 'firsttry'],
	['flatgroundskate', 'flatgroundsession'],
	['grom', 'grommet', 'startthemyoung'],
	['ilikesk8', 'ilikeskate'],
	['learningtoskate', 'learningtoskateboard', 'learntoskate'],
	['raddad', 'skatedad', 'skatemom', 'actidad'],
	['skateboarddeck', 'deckgraphic'],
	['skateeveryday', 'skateeverydamnday'],
	['skatefam', 'skatefamily'],
	['smallbiz', 'shopsmall'],
	['supportlocal', 'supportyourlocalskater', 'supportyourlocalskateshop'],
	['utahskateboarding', 'utahskater'],
];

export default data;
