import React from 'react';

import useForm from './HashtagsPage.hooks';
import Buckets from './components/Buckets/Buckets';
import Input from './components/Input/Input';
import Output from './components/Output/Output';
import Navbar from './components/Navbar/Navbar';
import ListAlphabetical from './components/Lists/ListAlphabetical';
import ListSuggested from './components/Lists/ListSuggested';
import TypeSelector from './components/TypeSelector/TypeSelector';
import Header from '../../components/Header/Header';
import { HashtagsPageContext } from './context';
import './HashtagsPage.scss';

const HashtagsPage = () => {
	const formState = useForm();
	const { activeMenu, addedTagsList, selectedType } = formState;

	const isDirty = addedTagsList.length > 0;
	const dirtyClass = isDirty ? 'dirty' : '';

	return (
		<HashtagsPageContext.Provider
			value={{
				...formState,
			}}
		>
			<Header />
			{!!selectedType ? (
				<>
					<Navbar />
					<div className={`form-panel ${dirtyClass}`}>
						<div
							className={`
								${formState.activeMenu === 'input' ? '' : 'mobile-hidden'}
								input-panel
							`}
						>
							<Input />
						</div>
						<div
							className={`
								${activeMenu === 'output' ? '' : 'mobile-hidden'}
								output-panel
							`}
						>
							<Output />
						</div>
					</div>
					<Buckets />
					<ListAlphabetical />
					<ListSuggested />
				</>
			) : (
				<TypeSelector />
			)}
		</HashtagsPageContext.Provider>
	);
};

export default HashtagsPage;
