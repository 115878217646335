import React from 'react';

import Group from './Group';
import './Groups.scss';

const Groups = ({ groupData, searchTerm }: GroupsProps) => {
	const filteredGroupData = groupData.filter((group) =>
		group.includes(searchTerm.toLowerCase()),
	);

	return (
		<div className="group-list">
			{filteredGroupData.map((group, index) => (
				<Group key={index} group={group} searchTerm={searchTerm} />
			))}
		</div>
	);
};

type GroupsProps = {
	groupData: string[][];
	searchTerm: string;
};

export default Groups;
