import React from 'react';

import './RatioForm.css';
import { calcHeight } from './RatioForm.helpers';

const RatioForm = ({
	ratio1,
	ratio2,
	setRatio1,
	setRatio2,
	setWidth,
	width,
}: RatioFormProps) => (
	<form className="ratio-form">
		<div className="ratio-inputs">
			<input
				onChange={(event) => setRatio1(parseInt(event.currentTarget.value))}
				type="text"
				value={ratio1}
			/>
			<p>:</p>
			<input
				onChange={(event) => setRatio2(parseInt(event.currentTarget.value))}
				type="text"
				value={ratio2}
			/>
		</div>

		<hr />

		<div className="ratio-inputs">
			<input
				onChange={(event) => setWidth(parseInt(event.currentTarget.value))}
				type="text"
				value={width}
			/>
			<p>x</p>
			<input
				disabled
				readOnly
				type="text"
				value={calcHeight(ratio1, ratio2, width)}
			/>
		</div>
	</form>
);

type RatioFormProps = {
	ratio1: number;
	ratio2: number;
	setRatio1: React.Dispatch<React.SetStateAction<number>>;
	setRatio2: React.Dispatch<React.SetStateAction<number>>;
	setWidth: React.Dispatch<React.SetStateAction<number>>;
	width: number;
};

export default RatioForm;
