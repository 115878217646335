import { createContext } from 'react';

export type HashtagsPageContextType = {
	activeMenu: string;
	addedTagsList: string[];
	isCoppied: boolean;
	onAddSuggestedTag: (
		_e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		_hashtag: string,
	) => void;
	onAddTags: (group: string[]) => void;
	onClearText: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onCopyText: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onDeleteTag: (
		_e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		_hashtag: string,
	) => void;
	onSelectType: (type: string) => void;
	onSetActiveMenu: (menu: string) => void;
	onSetCoppied: (bool: boolean) => void;
	onToggleTagSeparator: () => void;
	selectedType: string | null;
	tagSeparator: string;
};

export const initialTags = ['hokiskateboards'];

export const HashtagsPageContext = createContext<HashtagsPageContextType>(
	{} as HashtagsPageContextType,
);
