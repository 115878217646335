import React from 'react';

import TextBlocksPageContents from './components/TextBlocksPageContents';
import Header from '../../components/Header/Header';
import './TextBlocksPage.css';

const TextBlockPage = () => (
	<div className="text-block-page">
		<Header />
		<TextBlocksPageContents />
	</div>
);

export default TextBlockPage;
