const data = [
	{
		list: ['7C8FCE', '5A6FB4', '415082', '27314F', '1b2136'],
		name: 'blue',
	},
	{
		list: ['b998be', '9d6ea4', '74317E', '512258', '35063d'],
		name: 'purple',
	},
	{
		list: ['b48d96', '9b6672', '824150', '683440', '412028'],
		name: 'red',
	},
	{
		list: ['CF9153', 'C2884E', 'B57F48', '9C6D3E', '5C4025'],
		name: 'orange',
	},
	{
		list: ['E8DF5D', 'DBD358', 'CFC653', 'B5AE48', '4F4C20'],
		name: 'yellow',
	},
	{
		list: ['96b48d', '729b66', '508241', '406834', '284120'],
		name: 'green',
	},
	{
		list: ['f7f7f7', 'dddddd', '8C8C8C', '505659', '454a4d'],
		name: 'grey',
	},
];

export default data;
