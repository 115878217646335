import React, { useContext } from 'react';

import classnames from 'classnames';

// import { Consumer } from '../../../../context/context';
// import connectContext from '../../../../context/connectContext';
import Controls from './Controls';
import NoTagsMessage from '../NoTagsMessage/NoTagsMessage';
import TextCoppiedMessaging from './TextCoppiedMessaging';
import { formatExportValue } from '../../../../helpers/helpers';
import './Output.scss';
import { HashtagsPageContext } from '../../context';

const Output = () => {
	const context = useContext(HashtagsPageContext);
	const { addedTagsList, tagSeparator } = context;
	const count = addedTagsList.length;
	const valueFormatted = formatExportValue(tagSeparator, addedTagsList);

	if (count <= 0) {
		return (
			<div className="mobile-only">
				<NoTagsMessage isVisible />
			</div>
		);
	}

	return (
		<div className="output-content">
			<p
				className={classnames('mobile-hidden', {
					'danger-text': count > 30,
				})}
			>
				Count: {count}
			</p>
			<textarea
				className="output"
				id="myInput"
				readOnly
				value={valueFormatted}
			/>
			<p>{valueFormatted}</p>
			<Controls />
			<TextCoppiedMessaging />
		</div>
	);
};

export default Output;
