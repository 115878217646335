import React, { useState } from 'react';

import DimensionList from './DimensionList';
import RatioForm from './RatioForm';
import Switch from './Switch';

const RatiosPageContents = () => {
	const [ratio1, setRatio1] = useState(16);
	const [ratio2, setRatio2] = useState(9);
	const [width, setWidth] = useState(100);

	const onSwitch = () => {
		setRatio1(ratio2);
		setRatio2(ratio1);
	};

	return (
		<>
			<RatioForm
				ratio1={ratio1}
				ratio2={ratio2}
				setRatio1={setRatio1}
				setRatio2={setRatio2}
				setWidth={setWidth}
				width={width}
			/>
			<Switch onSwitch={onSwitch} />
			<DimensionList setWidth={setWidth} />
		</>
	);
};

export default RatiosPageContents;
