import React, { useContext, useState } from 'react';

import ListSuggestedItem from '../Lists/ListSuggestedItem';
import './bucket.css';
import { HashtagsPageContext } from '../../context';

type BucketsProps = {
	hashtags: string[];
};

const Buckets = ({ hashtags }: BucketsProps) => {
	const context = useContext(HashtagsPageContext);
	const { addedTagsList, onAddSuggestedTag, onAddTags } = context;

	const listSet = new Set(addedTagsList);
	const [open, setOpen] = useState(false);
	const filteredGroup = hashtags.filter((item) => !listSet.has(item));

	if (filteredGroup.length === 0) return null;

	const addAll = () => onAddTags(filteredGroup);

	return (
		<li className="bucket">
			<div className="bucket-head" onClick={() => setOpen(!open)}>
				<p>{filteredGroup[0]}</p>
			</div>
			{open && (
				<div className="word-panel-section-content">
					<ul>
						{filteredGroup.map((hashtag, index) => (
							<ListSuggestedItem
								key={`${index}-${hashtag}`}
								callback={onAddSuggestedTag}
								hashtag={hashtag}
							/>
						))}
					</ul>
					<button className="btn btn-primary add-all" onClick={addAll}>
						Add All
					</button>
				</div>
			)}
		</li>
	);
};

export default Buckets;
