import React from 'react';

import './DimensionList.css';

const list = [1080, 2160, 3840];

const DimensionList = ({ setWidth }: DimensionListProps) => (
	<div className="dimension-list">
		{list.map((item, index) => (
			<button
				key={index}
				className="btn btn-primary"
				onClick={() => setWidth(item)}
			>
				{item}
			</button>
		))}
	</div>
);

type DimensionListProps = {
	setWidth: React.Dispatch<React.SetStateAction<number>>;
};

export default DimensionList;
