import React, { useEffect, useRef, useState } from 'react';

import { formatText } from './Canvas.helpers';
import './Canvas.css';

const WIDTH = 600;

const Canvas = ({ fontSize, hex, text }: CanvasProps) => {
	const [imgSrc, setImgSrc] = useState<string | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	useEffect(() => {
		updateCanvas();
	}, [fontSize, hex, text]);

	const updateCanvas = () => {
		const paddingX = 20;
		const paddingY = 10;

		const font = `${fontSize}px Arial`;

		const textArray = text
			.trim()
			.split('/')
			.map(formatText)
			.filter((text) => text !== '');
		const rectHeight = parseInt(font, 10) + paddingY * 2;

		const canvasHeight = rectHeight * textArray.length;
		let canvasWidth = 0;

		if (!canvasRef.current) return;

		const ctx = canvasRef.current.getContext('2d');

		if (!ctx) return;

		ctx.clearRect(0, 0, WIDTH, canvasHeight);

		// set font
		ctx.font = font;
		// draw text from top
		ctx.textBaseline = 'middle';
		// draw text from center
		ctx.textAlign = 'center';

		textArray.forEach((textItem) => {
			// width of text
			const textWidth = ctx.measureText(textItem).width;

			// rectangle
			const rectWidth = textWidth + paddingX + paddingX;

			if (rectWidth > canvasWidth) canvasWidth = rectWidth;
		});

		// resize canvas
		canvasRef.current.width = canvasWidth;
		canvasRef.current.height = canvasHeight;

		const textX = canvasWidth / 2;

		// set font
		ctx.font = font;
		// draw text from top
		ctx.textBaseline = 'middle';
		// draw text from center
		ctx.textAlign = 'center';

		textArray.forEach((textItem, lineIndex) => {
			// width of text
			const textWidth = ctx.measureText(textItem).width;

			const centerX = (canvasWidth - textWidth) / 2;
			const startY = rectHeight * lineIndex;

			// rectangle
			const rectWidth = textWidth + paddingX + paddingX;
			const rectX = centerX - paddingX;
			const rectY = startY;

			// text
			const textY = startY + rectHeight / 2 + paddingY;

			// background color
			ctx.fillStyle = `#${hex}`;

			// draw background rect assuming height of font
			ctx.fillRect(rectX, rectY, rectWidth, rectHeight);

			// text color
			ctx.fillStyle = '#FFF';

			// draw text on top
			ctx.fillText(textItem, textX, textY);
		});

		const newImgSrc = canvasRef.current.toDataURL('image/png');

		setImgSrc(newImgSrc);
	};

	return (
		<>
			<canvas height={fontSize} ref={canvasRef} width={WIDTH} />
			{text && imgSrc && (
				<a download={text.replace(/ /g, '-')} href={imgSrc}>
					<img alt="download" className="image-download" src={imgSrc} />
				</a>
			)}
		</>
	);
};

type CanvasProps = {
	fontSize: number;
	hex: string;
	text: string;
};

export default Canvas;
