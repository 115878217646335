import React, { useContext } from 'react';

import Bucket from './Bucket';
import groupDataPhoto from '../../../../data/groups.photo';
import groupDataSkate from '../../../../data/groups.skate';
import './Buckets.css';
import { HashtagsPageContext } from '../../context';

const Buckets = () => {
	const context = useContext(HashtagsPageContext);
	const { activeMenu, selectedType } = context;
	const groupData = selectedType === 'skate' ? groupDataSkate : groupDataPhoto;

	return (
		<div
			className={`
			${activeMenu === 'buckets' ? '' : 'mobile-hidden'}
			buckets mobile-full-page ListBuckets
		`}
		>
			<ul>
				{groupData.map((hashtags, index) => (
					<Bucket key={index} hashtags={hashtags} />
				))}
			</ul>
		</div>
	);
};

export default Buckets;
