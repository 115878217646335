import React, { useState } from 'react';

import Canvas from './Canvas';
import ColorPicker from './ColorPicker';
import Form from './Form';
import Sizes from './Sizes';

const FONT_INCREMENT = 10;

const TextBlocksPageContents = () => {
	const [hex, setHex] = useState('415082');
	const [text, setText] = useState('');
	const [fontSize, setFontSize] = useState(70);

	return (
		<div className="app">
			<Form
				onChangeText={(event) => setText(event.currentTarget.value)}
				text={text}
			/>
			<Canvas fontSize={fontSize} hex={hex} text={text} />
			<Sizes
				onClickMinusSize={() => setFontSize(fontSize - FONT_INCREMENT)}
				onClickPlusSize={() => setFontSize(fontSize + FONT_INCREMENT)}
			/>
			<ColorPicker onChange={setHex} value={hex} />
		</div>
	);
};

export default TextBlocksPageContents;
