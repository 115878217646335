import React from 'react';

import Header from '../../components/Header/Header';
import './NotFoundPage.css';

const NotFoundPage = () => (
	<div className="no-found-page">
		<Header />
		<h1 className="margin">Page Not Found</h1>
	</div>
);

export default NotFoundPage;
