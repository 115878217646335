import React, { useContext } from 'react';

import ListSuggestedItem from '../Lists/ListSuggestedItem';
import { HashtagsPageContext } from '../../context';

const Group = ({ group, searchTerm }: GroupProps) => {
	const context = useContext(HashtagsPageContext);
	const { addedTagsList, onAddSuggestedTag } = context;

	const listSet = new Set(addedTagsList);
	const filteredGroup = group
		.filter((item) => item !== searchTerm.toLowerCase())
		.filter((item) => !listSet.has(item));

	if (filteredGroup.length === 0) return null;

	return (
		<div className="group">
			<div className="word-panel-section-content">
				<ul>
					{filteredGroup.map((hashtag, index) => (
						<ListSuggestedItem
							key={`${index}-${hashtag}`}
							callback={onAddSuggestedTag}
							hashtag={hashtag}
						/>
					))}
				</ul>
			</div>
		</div>
	);
};

type GroupProps = {
	group: string[];
	searchTerm: string;
};

export default Group;
