import React from 'react';
import { NavLink } from 'react-router-dom';

import './Nav.css';

const Nav = () => (
	<ul className="nav">
		<li>
			<NavLink
				className={(navData) => (navData.isActive ? 'active' : 'none')}
				to="/"
			>
				Hashtags
			</NavLink>
		</li>
		<li>
			<NavLink
				className={(navData) => (navData.isActive ? 'active' : 'none')}
				to="/ratios"
			>
				Ratios
			</NavLink>
		</li>
		<li>
			<NavLink
				className={(navData) => (navData.isActive ? 'active' : 'none')}
				to="/text-blocks"
			>
				Text Blocks
			</NavLink>
		</li>
	</ul>
);

export default Nav;
