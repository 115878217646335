import React, { useContext, useState } from 'react';

import classnames from 'classnames';

import {
	cleanText,
	filterNull,
	mapLowercase,
} from '../../../../helpers/helpers';
import groupDataPhoto from '../../../../data/groups.photo';
import groupDataSkate from '../../../../data/groups.skate';
import Groups from '../Groups/Groups';
import './Input.scss';
import { HashtagsPageContext } from '../../context';

const FormTextarea = () => {
	const context = useContext(HashtagsPageContext);
	const { onAddTags, selectedType } = context;
	const groupData = selectedType === 'skate' ? groupDataSkate : groupDataPhoto;

	const [searchTerm, setSearchTerm] = useState('');

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newSearchTerm = e.target.value;

		setSearchTerm(newSearchTerm);
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const wordArray = cleanText(searchTerm)
			.split(' ')
			.filter(filterNull)
			.map(mapLowercase);

		onAddTags(wordArray);
		setSearchTerm('');
	};

	const dirty = !!searchTerm;
	const single = searchTerm.indexOf(' ') === -1;

	return (
		<>
			<form
				className={classnames('input-form', {
					dirty,
					single,
				})}
				onSubmit={onSubmit}
			>
				<textarea
					autoCapitalize="off"
					autoComplete="off"
					autoCorrect="off"
					onChange={onChange}
					placeholder="Type Hashtags..."
					spellCheck="false"
					value={searchTerm}
				/>
				{searchTerm !== '' && (
					<div className="submit-container">
						<input className="btn btn-primary" type="submit" value="Add" />
					</div>
				)}
			</form>
			{searchTerm !== '' && (
				<Groups groupData={groupData} searchTerm={searchTerm} />
			)}
		</>
	);
};

export default FormTextarea;
