import React from 'react';

import RatiosPageContents from './RatiosPageContents';
import Header from '../../components/Header/Header';
import './RatiosPage.css';

const RatiosPage = () => (
	<div className="ratios-page">
		<Header />
		<RatiosPageContents />
	</div>
);

export default RatiosPage;
