import React, { useContext } from 'react';

import './TextCoppiedMessaging.scss';
import { HashtagsPageContext } from '../../context';

const TextCoppiedMessaging = () => {
	const context = useContext(HashtagsPageContext);
	const { addedTagsList, isCoppied } = context;
	const hasTags = addedTagsList.length > 0;

	if (!hasTags || !isCoppied) return null;

	return <p className="coppied-messaging">Coppied to clipboard!</p>;
};

export default TextCoppiedMessaging;
