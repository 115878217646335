import React, { useContext } from 'react';

import './Controls.scss';
import { HashtagsPageContext } from '../../context';

const Controls = () => {
	const context = useContext(HashtagsPageContext);
	const {
		addedTagsList,
		onClearText,
		onCopyText,
		onToggleTagSeparator,
		tagSeparator,
	} = context;
	const hasTags = addedTagsList.length > 0;

	if (!hasTags) return null;

	return (
		<div className="controls">
			<button className="btn btn-primary" onClick={onCopyText}>
				Copy
			</button>
			<button className="btn btn-danger" onClick={onClearText}>
				Clear
			</button>
			<button className="btn btn-primary" onClick={onToggleTagSeparator}>
				{tagSeparator}
			</button>
		</div>
	);
};

export default Controls;
