const suggestedList = [
	'1stbday',
	'1stbirthday',
	'firstbday',
	'firstbirthday',

	'adorable',

	'babyboy',
	'babygirl',
	'babyphotos',
	'babypics',
	'birthday',
	'birthdaycake',
	'birthdayideas',

	'cakesmash',
	'cakesmashphotography',
	'cakesmashphotographyutah',
	'cakesmashtheme',
	'cakesmashutah',
	'cute',

	'darling',

	'family',
	'familyphotography',
	'familyphotographyutah',

	'instakids',

	'kids',
	'kidsphotography',
	'kidsphotographyUtah',

	'maternity',
	'maternityphotography',
	'maternityphotographyutah',

	'newborn',
	'newbornphotography',
	'newbornphotographyutah',

	'oneyearold',
	'orem',
	'oremphotography',
	'oremphotographer',

	'photographerutah',
	'photography',
	'photographyutah',
	'pics',

	'pleasantgrove',
	'pleasantgrovephotography',
	'pleasantgrovephotographer',

	'senior',
	'seniorphotography',

	'turning1',

	'unicorn',
	'unicorncakesmash',
	'unicornparty',
	'utah',
	'utahcounty',
	'utahcountyphotos',
	'utahphotographer',
	'utahphotographers',
	'utahphotography',
];

export default suggestedList;
