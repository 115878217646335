import React from 'react';

import './Switch.css';

const Switch = ({ onSwitch }: SwitchProps) => (
	<button className="btn btn-primary switch-button" onClick={onSwitch}>
		Switch
	</button>
);

type SwitchProps = {
	onSwitch: () => void;
};

export default Switch;
