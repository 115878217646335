import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HashtagsPage from '../../routes/hashtags/HashtagsPage';
import NotFoundPage from '../../routes/not-found/NotFoundPage';
import RatiosPage from '../../routes/ratios/RatiosPage';
import TextBlocksPage from '../../routes/text-blocks/TextBlocksPage';
import '../../scss/general.css';

const App = () => (
	<Router>
		<Routes>
			<Route element={<HashtagsPage />} path="/" />
			<Route element={<HashtagsPage />} path="/hashtags" />
			<Route element={<TextBlocksPage />} path="/text-blocks" />
			<Route element={<RatiosPage />} path="/ratios" />
			<Route element={<NotFoundPage />} path="*" />
		</Routes>
	</Router>
);

export default App;
