const data = [
	'skateanddestroy',
	'skateboarddeck',
	'skateboardgraphic',
	'skateboardingclips',
	'skateboardingisfun',
	'skateboardingislife',
	'skateboardingismylife',
	'skateboardingisnotacrime',
	'skateboardinglove',
	'skateboardingtoddler',
	'skateboardline',
	'skatecrunch',
	'skatecrunchmag',
	'skatedad',
	'skatedadbrand',
	'skatedeck',
	'skatedeckart',
	'skateeverydamnday',
	'skateeveryday',
	'skatefam',
	'skatefamily',
	'skatefamily',
	'skategroundmag',
	'skatekid',
	'skatelife',
	'skatelifestyle',
	'skateline',
	'skatelove',
	'skatemom',
	'skateordie',
	'skatepark',
	'skateparkline',
	'skateparksession',
	'skatephoto',
	'skatephotography',
	'skaterdad',
	'skaterkid',
	'skatespot',
	'skatetoddler',
	'smallbiz',
	'smallbusiness',
	'startthemyoung',
	'startup',
	'streetstyle',
];

export default data;
