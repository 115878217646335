import { TAG_SEPARATOR } from '../routes/hashtags/constants';

export const copyStringToClipboard = (str: string) => {
	// Create new element
	const el = document.createElement('textarea');
	// Set value (string to be copied)
	el.value = str;
	// Set non-editable to avoid focus and move outside of view
	el.setAttribute('readonly', '');
	el.setAttribute('style', "left: '-9999px'; position: 'absolute';");
	document.body.appendChild(el);
	// Select text inside element
	el.select();
	// Copy text to clipboard
	document.execCommand('copy');
	// Remove temporary element
	document.body.removeChild(el);
};

export const isEmpty = (str?: string) =>
	str === null || str === undefined || str === '' || str === ' ';

export const filterNull = (str?: string) => !isEmpty(str);

export const filterOutDuplicates = (array: string[]) => {
	const uniqueSet = new Set(array);

	return [...Array.from(uniqueSet)];
};

export const mapAddPrefix = (tagSeparator: string, str: string) =>
	`${tagSeparator}${str}`;

export const cleanText = (str: string) => str.replace(/[^a-zA-Z\s]/g, '');

export const mapLowercase = (str: string) => str.toLowerCase();

export const formatExportValue = (tagSeperator: string, tags: string[]) => {
	if (tagSeperator === TAG_SEPARATOR.COMMA) return tags.join(', ');

	return tags.map((str) => mapAddPrefix(tagSeperator, str)).join(' ');
};
