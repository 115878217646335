import React from 'react';

import './Form.css';

const Form = ({ onChangeText, text }: FormProps) => (
	<form>
		<input onChange={onChangeText} value={text} />
	</form>
);

type FormProps = {
	onChangeText: React.ChangeEventHandler<HTMLInputElement>;
	text: string;
};

export default Form;
