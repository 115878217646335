import b from './skate/b';
import d from './skate/d';
import f from './skate/f';
import g from './skate/g';
import h from './skate/h';
import i from './skate/i';
import l from './skate/l';
import r from './skate/r';
import s from './skate/s';
import t from './skate/t';
import u from './skate/u';
import y from './skate/y';

const suggestedList = [
	...b,
	...d,
	...f,
	...g,
	...h,
	...i,
	...l,
	...r,
	...s,
	...t,
	...u,
	...y,
];

export default suggestedList;
