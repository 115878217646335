import React, { useContext } from 'react';

import './NoTagsMessage.scss';
import { HashtagsPageContext } from '../../context';

const NoTagsMessage = ({ isVisible }: NoTagsMessageProps) => {
	const context = useContext(HashtagsPageContext);
	const { onSetActiveMenu } = context;

	if (!isVisible) return null;

	return (
		<div className="no-tags-message">
			<p className="empty-messaging">Type some hashtags</p>
			<button
				className="btn btn-primary"
				onClick={() => onSetActiveMenu('input')}
			>
				Go
			</button>
		</div>
	);
};

type NoTagsMessageProps = {
	isVisible: boolean;
};

export default NoTagsMessage;
