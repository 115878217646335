import React from 'react';

const ListAlphabeticalItem = ({
	callback,
	hashtag,
}: ListAlphabeticalItemProps) => (
	<li>
		{hashtag}
		<button className="btn-delete" onClick={(e) => callback(e, hashtag)}>
			-
		</button>
	</li>
);

type ListAlphabeticalItemProps = {
	callback: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		hashtag: string,
	) => void;
	hashtag: string;
};

export default ListAlphabeticalItem;
