import React, { useContext } from 'react';

import NoTagsMessage from '../NoTagsMessage/NoTagsMessage';
import ListAlphabeticalItem from './ListAlphabeticalItem';
import { HashtagsPageContext } from '../../context';

const ListAlphabetical = () => {
	const context = useContext(HashtagsPageContext);
	const { activeMenu, addedTagsList, onDeleteTag } = context;

	return (
		<div
			className={`
			${activeMenu === 'alphabetical' ? '' : 'mobile-hidden'}
			mobile-full-page
			word-panel-section
			word-panel-section-content
			ListAlphabetical
		`}
		>
			<ul>
				{addedTagsList.map((hashtag, index) => (
					<ListAlphabeticalItem
						key={`${index}-${hashtag}`}
						callback={onDeleteTag}
						hashtag={hashtag}
					/>
				))}
			</ul>
			<NoTagsMessage isVisible={!addedTagsList.length} />
		</div>
	);
};

export default ListAlphabetical;
