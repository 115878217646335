import React, { useContext } from 'react';

import ListSuggestedItem from './ListSuggestedItem';
import suggestedListSkate from '../../../../data/suggestedList.skate';
import suggestedListPhoto from '../../../../data/suggestedList.photo';
import { HashtagsPageContext } from '../../context';

const ListSuggested = () => {
	const context = useContext(HashtagsPageContext);
	const { activeMenu, addedTagsList, onAddSuggestedTag, selectedType } =
		context;
	const tagsSet = new Set(addedTagsList);
	const suggestedList =
		selectedType === 'skate' ? suggestedListSkate : suggestedListPhoto;
	const tags = suggestedList.filter((item) => !tagsSet.has(item));

	return (
		<div
			className={`
 			${activeMenu === 'suggested' ? '' : 'mobile-hidden'}
 			mobile-full-page
			word-panel-section
			word-panel-section-content
			ListSuggested
		`}
		>
			<ul>
				{tags.map((hashtag, index) => (
					<ListSuggestedItem
						key={`${index}-${hashtag}`}
						callback={onAddSuggestedTag}
						hashtag={hashtag}
					/>
				))}
			</ul>
		</div>
	);
};

export default ListSuggested;
