const data = [
	'familybrand',
	'familybusiness',
	'firsttry',
	'flatgroundsession',
	'flatgroundskate',
	'fliptrick',
	'freestyleskate',
	'freestyleskateboarding',
];

export default data;
