const data = [
	[
		'1stbday',
		'1stbirthday',
		'firstbday',
		'firstbirthday',
		'oneyearold',
		'turning1',
	],
	['babyphotos', 'babypics'],
	['birthday', 'birthdaycake', 'birthdayideas'],
	['adorable', 'cute', 'darling'],
	[
		'cakesmash',
		'cakesmashphotography',
		'cakesmashphotographyutah',
		'cakesmashtheme',
		'cakesmashutah',
	],
	['newborn', 'newbornphotography', 'newbornphotographyutah'],
	['kids', 'kidsphotography', 'kidsphotographyutah'],
	['family', 'familyphotography', 'familyphotographyutah'],
	['maternity', 'maternityphotography', 'maternityphotographyutah'],
	['orem', 'oremphotography', 'oremphotographer'],
	['photography', 'photographyutah', 'photos', 'pics'],
	['photographer', 'photographerutah'],
	['pleasantgrove', 'pleasantgrovephotography', 'pleasantgrovephotographer'],
	['senior', 'seniorphotography', 'seniorphotographyutah'],
	['unicorn', 'unicorncakesmash', 'unicornparty'],
	['utahcounty', 'utahcountyphotos', 'utahphotographer', 'utahphotographers'],
];

export default data;
