import { useState } from 'react';

import { TAG_SEPARATOR } from './constants';
import {
	copyStringToClipboard,
	filterOutDuplicates,
	formatExportValue,
} from '../../helpers/helpers';
import { HashtagsPageContextType, initialTags } from './context';

const useForm = (): HashtagsPageContextType => {
	const [tagSeparator, setTagSeparator] = useState(TAG_SEPARATOR.HASHTAG);
	const [addedTagsList, setAddedTagsList] = useState<string[]>(initialTags);
	const [activeMenu, setActiveMenu] = useState('input');
	const [isCoppied, setIsCoppied] = useState(false);
	const [selectedType, setSelectedType] = useState<string | null>(null);

	const onDeleteTag = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		hashtag: string,
	) => {
		e.preventDefault();
		const newAddedTagsList = addedTagsList.filter((item) => item !== hashtag);

		setAddedTagsList(newAddedTagsList);
	};

	const onAddSuggestedTag = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		hashtag: string,
	) => {
		e.preventDefault();

		onAddTags([hashtag]);
	};

	const onAddTags = (group: string[]) => {
		const newAddedTags = filterOutDuplicates([...addedTagsList, ...group]);

		setAddedTagsList(newAddedTags);
	};

	const onClearText = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		const newAddedTagsList: string[] = [];
		setAddedTagsList(newAddedTagsList);
	};

	const onCopyText = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		const valueFormatted = formatExportValue(tagSeparator, addedTagsList);
		copyStringToClipboard(valueFormatted);
		onSetCoppied(true);

		const timer = window.setInterval(() => {
			onSetCoppied(false);
			window.clearInterval(timer);
		}, 3000);
	};

	const onSetCoppied = (bool: boolean) => {
		setIsCoppied(bool);
	};

	const onSetActiveMenu = (menu: string) => {
		setActiveMenu(menu);
	};

	const onSelectType = (type: string) => {
		setSelectedType(type);
	};

	const onToggleTagSeparator = () => {
		const newtagSeparator =
			tagSeparator === TAG_SEPARATOR.HASHTAG
				? TAG_SEPARATOR.COMMA
				: TAG_SEPARATOR.HASHTAG;
		setTagSeparator(newtagSeparator);
	};

	return {
		activeMenu,
		addedTagsList,
		isCoppied,
		onAddSuggestedTag,
		onAddTags,
		onClearText,
		onCopyText,
		onDeleteTag,
		onSelectType,
		onSetActiveMenu,
		onSetCoppied,
		onToggleTagSeparator,
		selectedType,
		tagSeparator,
	};
};

export default useForm;
