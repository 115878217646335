import React, { useState } from 'react';

import colorData from './ColorPicker.data';
import Star from './Star';
import './ColorPicker.css';

const ColorPicker = ({ onChange, value }: ColorPickerProps) => {
	const [isOpen, setIsOpen] = useState(true);

	return (
		<>
			<p className="color-toggle" onClick={() => setIsOpen(!isOpen)}>
				Colors
			</p>

			{isOpen && (
				<ul className="color-picker">
					{colorData.map((color, index) => (
						<li key={`${color}-${index}`}>
							<ul className="color-picker-row-list">
								{color.list.map((hex, index) => (
									<li
										key={`${hex}-${index}`}
										className="color-picker-item"
										onClick={() => onChange(hex)}
										style={{
											background: `#${hex}`,
										}}
									>
										{hex === value && <Star />}
									</li>
								))}
							</ul>
						</li>
					))}
				</ul>
			)}
		</>
	);
};

type ColorPickerProps = {
	onChange: (hex: string) => void;
	value: string;
};

export default ColorPicker;
