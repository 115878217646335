import React from 'react';

import Nav from './Nav/Nav';
import './Header.css';

const Header = () => (
	<div className="header">
		<Nav />
	</div>
);

export default Header;
