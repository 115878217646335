import React, { useContext } from 'react';

import './TypeSelector.css';
import { HashtagsPageContext } from '../../context';

const TypeSelector = () => {
	const context = useContext(HashtagsPageContext);
	const { onSelectType } = context;

	return (
		<div className="type-selector">
			<button className="btn btn-primary" onClick={() => onSelectType('skate')}>
				Skate
			</button>
			<button className="btn btn-primary" onClick={() => onSelectType('photo')}>
				Photo
			</button>
		</div>
	);
};

export default TypeSelector;
