import React, { useContext } from 'react';

import './Navbar.scss';
import { HashtagsPageContext } from '../../context';

const Navbar = () => {
	const context = useContext(HashtagsPageContext);
	const { activeMenu, addedTagsList, onSetActiveMenu } = context;
	const count = addedTagsList.length;

	return (
		<div className="navbar">
			<div
				className={`
			navbar-item
			mobile-only
			word-panel-section
			word-panel-section-header
			${activeMenu === 'input' ? 'active' : ''}
		`}
			>
				<p onClick={() => onSetActiveMenu('input')}>Input</p>
			</div>
			<div
				className={`
			navbar-item
			word-panel-section
			word-panel-section-header
			ListAlphabetical
			${activeMenu === 'alphabetical' ? 'active' : ''}
		`}
			>
				<p onClick={() => onSetActiveMenu('alphabetical')}>List</p>
			</div>
			<div
				className={`
			navbar-item
			word-panel-section
			word-panel-section-header
			ListSuggested
			${activeMenu === 'buckets' ? 'active' : ''}
			`}
			>
				<p onClick={() => onSetActiveMenu('buckets')}>Buckets</p>
			</div>
			<div
				className={`
			navbar-item
			word-panel-section
			word-panel-section-header
			ListSuggested
			${activeMenu === 'suggested' ? 'active' : ''}
		`}
			>
				<p onClick={() => onSetActiveMenu('suggested')}>Suggested</p>
			</div>
			<div
				className={`
			navbar-item
			mobile-only
			word-panel-section
			word-panel-section-header
			${activeMenu === 'output' ? 'active' : ''}
			${count > 30 ? 'danger-background' : ''}
		`}
			>
				<p onClick={() => onSetActiveMenu('output')}>{count}</p>
			</div>
		</div>
	);
};

export default Navbar;
