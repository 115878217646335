import React from 'react';

const ListSuggestedItem = ({ callback, hashtag }: ListSuggestedItemProps) => (
	<li>
		{hashtag}
		<button className="btn-delete" onClick={(e) => callback(e, hashtag)}>
			+
		</button>
	</li>
);

type ListSuggestedItemProps = {
	callback: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		hashtag: string,
	) => void;
	hashtag: string;
};

export default ListSuggestedItem;
