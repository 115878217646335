import React from 'react';

import './Sizes.css';

const Sizes = ({ onClickMinusSize, onClickPlusSize }: SizesProps) => (
	<div className="margin-bottom font-sizes">
		<button className="btn btn-primary" onClick={onClickMinusSize}>
			-
		</button>
		<button className="btn btn-primary" onClick={onClickPlusSize}>
			+
		</button>
	</div>
);

type SizesProps = {
	onClickMinusSize: () => void;
	onClickPlusSize: () => void;
};

export default Sizes;
